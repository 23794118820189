<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Reporte de ventas por categoria</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Última actualización {{ Date.now() | date }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col class="d-flex align-items-center justify-content-center"> 
          <label for="frequency" class="mr-1">Seleccionar un periodo actual:</label>
          <select id="frequency" v-model="selectedFrequency" @change="fetchData">
            <option value="daily">Día</option>
            <option value="weekly">Semana</option>
            <option value="monthly">Mes</option>
            <option value="yearly">Año en curso</option>
            <option value="all">Desde el inicio</option>
            <option value="custom" disabled>
              Personalizado
            </option>
          </select>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-center">
          <label for="range-picker">Seleccionar un rango de fechas:</label>
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range'}"
            class="form-control  bg-transparent border-0 shadow-none"
            placeholder="YYYY-MM-DD"
            @on-change="fetchDataByRange()"
          />   
        </b-col>
      </b-row>
      <b-card>
        <b-row>
          <b-col>
            <div>
              <b-table
                class="position-relative"
                :items="formattedColumns"
                :fields="clientsTableColumns"
                responsive
                primary-key="id"
                show-empty
                empty-text="No existen registros aún"
                hover
                @row-clicked="openDetails"
              >
                <template #cell(client_name)="data">
                  <span class="text-capitalize text-nowrap text-primary">{{ data.value }}</span>
                </template>
                <template #cell(total_not_payed)="data">
                  <b-badge 
                    pill
                    :variant="(data.value > 0) ? 'danger' : 'light-warning'"
                  >
                    <span>${{ data.value }}</span>
                  </b-badge>
                </template>
                <template #cell(total_with_tax)="data">
                  <b-badge 
                    pill
                    :variant="(data.value === 0) ? 'danger' : 'light-info'"
                  >
                    <span>${{ data.value }}</span>
                  </b-badge>
                </template>
                <template #cell(total_without_tax)="data">
                  <b-badge 
                    pill
                    :variant="(data.value === 0) ? 'danger' : 'light-success'"
                  >
                    <span>${{ data.value }}</span>
                  </b-badge>
                </template>
              </b-table>
            </div>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Mostrando {{ pagination.count }} de {{ pagination.total_objects }} registros</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="pagination.page"
                    :total-rows="pagination.total_objects"
                    :per-page="pagination.per_page"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item" 
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"

                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
                </b-row>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="12">
            <div class="chart-container">
              <vue-apex-charts
                height="320"
                type="bar"
                :options="chartOptions"
                :series="series"
                class="mt-4"
              />
            </div>
          </b-col>
        </b-row> -->
      </b-card>
    </b-card-body>
    <b-modal v-model="showModal" ok-only scrollable>
      <div class="d-flex align-items-center">
        <b-avatar size="4rem" :src="selectedClient.client_logo"/>
        <div class="ml-1 mt-1" v-if="selectedClient.client">
          <h4>
            {{ selectedClient.client.name }} 
          </h4>
          <h4>
            {{ selectedClient.client.email }} 
          </h4>
        </div>
      </div>      
      <h4 class="text-center mt-1">Ordenes compradas por categoria</h4>
      <b-list-group v-for="(count, category) in selectedClient.category">
        <b-list-group-item>
          <h5 class="text-capitalize text-nowrap text-primary">
            <b-badge variant="light-primary">
                {{ category }}
            </b-badge>
          </h5>
          <b-badge variant="dark">
            Cantidad comprada: {{ count }}
          </b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import VueApexCharts from 'vue-apexcharts'
import Statistics from '@core/components/CustomerStatistics.vue'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BTable,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // VueApexCharts,
    Statistics,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BTable,
    flatPickr,
  },
  data() {
    return {
      selectedFrequency: 'all',
      rangePicker: [],
      pagination: {
        page: 1,
        per_page: 10, 
        total_objects: 0, 
        count: 0
      },
      clientsTableColumns: [
        {
          key: 'client.name',
          label: 'Clientes',
          sortable: true,
        },
        {
          key: 'participants_count',
          label: 'Total de participantes',
          sortable: true,
        },
        { 
          key: 'sells_count',
          label: 'Ventas por categoría',
          sortable: true,
        },
        {
          key: 'sells_payed_count',
          label: 'Ventas pagadas',
          sortable: true,
        },
        {
          key: 'total_not_payed',
          label: 'Total a pagar',
          sortable: true,
        },
        {
          key: 'total_with_tax',
          label: 'Total con IVA',
          sortable: true,
        },
        {
          key: "total_without_tax",
          label: 'Total sin IVA',
          sortable: true,
        },
      ],
      selectedClient: {},
      showModal: false,
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  beforeMount() { 
    this.fetchClientsReport({ storeId: this.$route.params.id, frequency: this.selectedFrequency })
      .finally(() => {
        this.pagination.total_objects = this.clientsReport.length;
        this.selectedClient = this.clientsReport[1]
      })
  },
  computed: {
    ...mapGetters('dashboard', ['clientsReport']),
    formattedColumns(){
      if (!this.clientsReport.length) return []

      const startIndex = (this.pagination.page - 1) * this.pagination.per_page;
      const endIndex = startIndex + this.pagination.per_page;
      this.pagination.count = this.clientsReport.slice(startIndex, endIndex).length;

      return this.clientsReport.slice(startIndex, endIndex);
    }
  },
  methods: {
    ...mapActions('dashboard', ['fetchClientsReport']),
    fetchData() {
      this.$swal({
        title: 'Cargando...',
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })
      this.fetchClientsReport({ storeId: this.$route.params.id, frequency: this.selectedFrequency })
      .then(() => {
        this.pagination.total_objects = this.clientsReport.length;
        if(this.clientsReport.length === 0) {
          this.pagination.count = 0;
        }
      })
      .finally(() => {
        this.$swal.close()
      })
    },
    fetchDataByRange(){
      const startDate = Array.isArray(this.rangePicker) ? this.rangePicker[0] : this.rangePicker.substring(0, 10)
      const endDate = Array.isArray(this.rangePicker) ? this.rangePicker[1] : this.rangePicker.substring(13, 24)
      if(!startDate){
        return
      }
      const trimmedStartDate = startDate.replace(/ /g, '')
      const trimmedEndDate = endDate ? endDate.replace(/ /g, '') : null
      const frequency = trimmedEndDate ? `${trimmedStartDate}_${trimmedEndDate}` : `${trimmedStartDate}_${trimmedStartDate}`
      this.selectedFrequency = 'custom'
      this.fetchClientsReport({ storeId: this.$route.params.id, frequency: frequency })
    },
    openDetails(item, index, event) {
      this.selectedClient = item
      this.showModal = true
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

  .small-title .card-header {
    font-size: 12px; /* Adjust the font size as needed */
  }
  .light-success {
    color: #00cfe8; /* Replace with the desired color value */
    font-weight: bold;
  }
  .small-subtitles .card-subtitle {
    font-size: 5px; /* Adjust the font size as needed */
  }
  .custom-span {
    font-weight: bold;
    color: #00cfe8;
  }

  .custom-span-white {
    font-weight: bold;
    color: #d4d9d4;
  }

  .chart-container {
    display: grid;
    grid-template-rows: min-content 1fr;
  }


</style>