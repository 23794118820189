<template>

  <!-- Table Container Card -->
  <b-card
    v-if="offlineOrders.length > 0"
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="d-flex justify-content-between">
        <b-col
          sm="auto"
          class="mb-1 mb-md-0"
        >
          <h3>Ventas pendientes de registrar</h3>
        </b-col>
        <b-col
          sm="auto"
          class="mb-1 mb-md-0"
        >
          <b-button
            variant="warning"
            @click="fetchPendingOrders()"
          >
            Registrar ventas
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-table
      :items="offlineOrders"
      :fields="orderColumns"
      responsive
      primary-key="id"
      show-empty
      empty-text="No tienes ventas sin registrar"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(folio)="data">
        <b-link
          :to="{ name: 'order-view', params: { id: data.item.data.id }}"
          class="font-weight-bold"
        >
          #{{ data.item.data.auto_inc_folio }}
        </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(productos)="data">
        {{ data.item.data.order_store_products_attributes.length }}
      </template>

      <!-- Column: store clerk -->
      <template #cell(store_clerk)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.data.store_clerk.logo"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.data.store_clerk.name }}
          </span>
          <small class="text-muted">{{ data.item.data.store_clerk.email }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(created_at)="data">
        <span class="text-nowrap mr-2">
          {{ data.item.data.created_at | dateNtime }}
        </span>
      </template>

      <!-- Column: Total -->
      <template #cell(total)="data">
        <div>
          <feather-icon
            :icon="data.item.data.order_type | orderTypeIcon"
            :class="data.item.data.order_type | orderTypeClass"
            class="mr-1"
          />
          ${{ data.item.data.total | money }}
        </div>
      </template>

      <!-- Column: Payment type -->
      <template #cell(order_payments_attributes)="data">
        <template>
          <b-badge
            pill
            :variant="data.item.data.order_payments_attributes[0].payment_type === 'cash' ? 'light-success' : 'light-info'"
          >
             {{ data.item.data.order_payments_attributes[0].payment_type | paymentType }}
          </b-badge>
          <b-badge
            v-if="data.item.data.is_refund"
            class="ml-1"
            pill
            variant="light-warning"
          >
            Devolución
          </b-badge>
        </template>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <label># de resultados</label>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      entriesPerPage: '10',
      statusOptions: [
        'Todos',
        'Walleats',
        'Efectivo',
        'Tarjetas bancarias',
      ],
      orderColumns: [
        {
          key: 'created_at',
          label: 'Fecha',
        },
        {
          key: 'store_clerk',
          label: 'Cajero',
        },
        {
          key: 'order_payments_attributes',
          label: 'Tipo de pago',
        },
        'productos',
        'total',
      ],
      perPage: ['10', '20', '50', '100'],
    }
  },
  computed: {
    ...mapGetters('orders', [
      'pagination',
      'offlineOrders',
    ]),
  },
  watch: {

  },
  mounted() {
    this.fetchOfflineOrders(this.$route.params.id)
  },
  methods: {
    ...mapActions('orders', [
      'fetchOfflineOrders',
      'addOrder',
      'dispatchBulkOrders',
      'deleteOrderFromIdexedDb',
    ]),
    fetchPendingOrders() {
      this.dispatchBulkOrders(this.$route.params.id)
        .then(() => {
          this.$swal({
            title: 'Ordenes registradas!',
            text: 'Todas las ordenes han sido registradas exitosamente',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
            .then(() => window.location.reload())
        })
        .catch(err => {
          /* eslint-disable-next-line */
          console.log(err)
        })
    },
    finishSale(order) {
      this.addOrder({
        order: order.data,
        orderType: order.data.order_type,
        bypassOffline: true,
      })
        .then(() => {
          this.deleteOrderFromIdexedDb(order)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input{
  max-width: 200px;
}
</style>
