<template>
  <div>
    <b-button
      block
      variant="outline-primary"
      @click="$bvModal.show('endOfDayModal')"
    >
      Reporte de corte de caja
    </b-button>

    <b-modal
      id="endOfDayModal"
      hide-footer
      size="xl"
    >
      <b-card
        no-body
      >
        <div class="m-2">
          <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            layout="horizontal"
            finish-button-text="Cerrar"
            next-button-text="Siguiente"
            back-button-text="Regresar"
            class="wizard-vertical mb-3"
            shape="square"
            step-size="sm"
            @on-complete="formSubmitted"
          >
            <tab-content title="Fecha de corte" class="align-items-center justify-content-center mb-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <h5 class="mb-0">
                    Selecciona la fecha deseada para la que deseas revisar el corte de caja
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <b-form-input
                    v-model="endOfDayDate"
                    class="date-input"
                    type="date"
                  />
                </b-col>
              </b-row>
            </tab-content>
            <tab-content title="Caja" v-if="endOfDayDate">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <h5 class="mb-0">
                    Selecciona el cajero del que deseas revisar el corte de caja
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <b-select v-model="selectedDrawer" value-field="id" text-field="store_clerk.name" :options="preparedOptions" class="drawer-select" />
                </b-col>
              </b-row>
            </tab-content>
            <tab-content title="Caja al corte" v-if="endOfDate.at_cash_out">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <h5 class="mb-0">
                    Información de caja al corte
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <statistics :data="salesStats" />
                </b-col>
              </b-row>
            </tab-content>
            <tab-content title="Caja después del corte" v-if="endOfDate.after_cash_out">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <h5 class="mb-0">
                    Información de caja después del corte
                  </h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center mb-2"
                >
                  <statistics :data="afterSalesStats" />
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </div>
      </b-card>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BButton,
  BModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import Statistics from '@core/components/CustomerStatistics.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import '@/@core/scss/vue/libs/vue-wizard.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent,
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    Statistics,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    store_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      endOfDayDate: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      selectedDrawer: null,
      salesStats: {
        cashInRegisterFinal: {
          title: 0,
          subtitle: 'Efectivo final de caja',
          icon: 'TrendingUpIcon',
          color: 'light-success',
          customClass: 'mb-1',
        },
        totalCash: {
          title: 0,
          subtitle: 'Efectivo total',
          icon: 'ShoppingBagIcon',
          color: 'light-info',
          customClass: 'mb-1',
        },
        cashDiscrepancy: {
          title: 0,
          subtitle: 'Efectivo sobrante',
          icon: 'ShoppingBagIcon',
          color: 'light-info',
          customClass: 'mb-1',
        },
      },
      afterSalesStats: {
        cashSales: {
          title: 0,
          subtitle: 'Ventas en efectivo',
          icon: 'TrendingUpIcon',
          color: 'light-success',
          customClass: 'mb-1',
        },
        cashInventoryPurchase: {
          title: 0,
          subtitle: 'Compras en efectivo a proveedores',
          icon: 'ShoppingBagIcon',
          color: 'light-info',
          customClass: 'mb-1',
        },
      },
    }
  },
  destroyed() {
    this.$bvModal.hide('endOfDayModal')
    this.endOfDayDate = null
    this.selectedDrawer = null
  },
  computed: {
    ...mapGetters('reports', ['endOfDate', 'openDays', 'openDay']),
    preparedOptions(){
      const filteredDays =  this.openDays.filter(day => day.status === 'closed')
      return [
        { id: null, store_clerk: { name: 'Todas las cajas' } },
        ...filteredDays
      ]
    }
  },
  watch: {
    endOfDayDate(val){
      this.selectedDrawer = null
      this.fetchOpenDays({ store_id: this.store_id, date: val })
      .catch(error => {
        this.$swal({
          title: 'Error!',
          text: error.response.data.messages[0],
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
      this.fetchPrepareEndOfAllDays({ store_id: this.store_id, date: val })
      .then((response) => this.updateStats(response))
      .catch(error => {
        this.$swal({
          title: 'Error!',
          text: error.response.data.messages[0],
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }) 
    },
    selectedDrawer(val){
      if(this.selectedDrawer){
        this.fetchPrepareEndOfDate({ open_day_id: this.selectedDrawer })
        .then((response) => this.updateStats(response))
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data.messages[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }else{
        this.fetchPrepareEndOfAllDays({ store_id: this.store_id, date: this.endOfDayDate })
        .then((response) => this.updateStats(response))
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.response.data.messages[0],
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }
    }  
  },
  methods: {
    ...mapActions('reports', [
      'fetchOpenDays',
      'fetchPrepareEndOfDate',
      'fetchPrepareEndOfAllDays',
      'fetchOpenDay'
    ]),
    formSubmitted() {
      this.$bvModal.hide('endOfDayModal')
    },
    formatDate(date){
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },
    updateStats(response){
      const cash_discrepancy_text = response.at_cash_out.cash_discrepancy < 0 
        ? 'Efectivo faltante' 
        : 'Efectivo sobrante'
        
      const cash_discrepancy_color = response.at_cash_out.cash_discrepancy < 0 
      ? 'light-danger' 
      : 'light-success'

      const cash_discrepancy_icon = response.at_cash_out.cash_discrepancy < 0
      ? 'TrendingDownIcon'
      : 'TrendingUpIcon'

      this.salesStats.cashInRegisterFinal.title = response.at_cash_out.cash_in_cash_register_final
      this.salesStats.totalCash.title = response.at_cash_out.total_cash
      this.salesStats.cashDiscrepancy.title = response.at_cash_out.cash_discrepancy
      this.salesStats.cashDiscrepancy.subtitle = cash_discrepancy_text
      this.salesStats.cashDiscrepancy.color = cash_discrepancy_color
      this.salesStats.cashDiscrepancy.icon = cash_discrepancy_icon
      
      this.afterSalesStats.cashSales.title = response.after_cash_out.cash_sales
      this.afterSalesStats.cashInventoryPurchase.title = response.after_cash_out.cash_inventory_purchase
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.text-red{
  color: #ea5455;
}
.text-green{
  color: #28c76f;
}
.text-yellow{
  color: #f3dc09;
}
.date-input{
  max-width: 200px;
}
.drawer-select{
  max-width: 250px;
}
</style>
